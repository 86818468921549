<template>
<v-container fluid>
  <v-row>
    <v-col cols="6">
      <v-card>
        <bbsavant></bbsavant>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card>
        <v-radio-group
          v-model="pov"
          mandatory
          row
        >
          <v-radio
            v-for="(radio, i) in povOptions" :key="i"
            :label="radio.text"
            :value="radio.value"
          ></v-radio>
        </v-radio-group>

        <strike-zone-hmap v-model="mappedValues"></strike-zone-hmap>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import merge from "lodash/merge";
import { Providers, Enums, Entities, Converters, ValueObjects } from '@flightscope/baseball-stats';
import { StrikeZoneDataBinning } from '@flightscope/baseball-stats/src/classificators/dataBinning';
import StrikeZoneHmap from '@/components/ui/charts/StrikeZoneHmap.vue';
import { POV } from '@/components/ui/charts/strikeZoneHelpers';
import bbsavant from './bbsavant.svg';
import * as data from '@flightscope/baseball-stats/test/data/kia_vs_hanwha_reprocessed.json';

export default {
  components: {
    bbsavant,
    StrikeZoneHmap,
  },

  data() {
    return {
      pov: POV.BATTER,
      povOptions: [
        { value: POV.BATTER, text: 'BATTER POV' },
        { value: POV.PITCHER, text: 'PITCHER POV' }
      ],

      mappedValues: [],
    };
  },

  methods: {
    initialSeries() {
      const binning = new StrikeZoneDataBinning();
      const provider = new Providers.StrikeZoneHeatMapSeriesProvider(binning);
      const binValueConverter = new Converters.BinValueConverters.BinToCountConverter();

      let chartSeries = provider.getSeriesForHeatMap(data.Results, binValueConverter);

      return chartSeries.series;
    },

    colorSteps(series) {
      if (!series.length) { return false; }

      // const ballStrikesStepsConverter = new Converters.RangeToStepsConverters.SymmetricRangeToStepsConverter(
      //   new Converters.RangeToStepsConverters.LinearRangeToStepsConverter(50),
      //   new Converters.RangeToStepsConverters.LinearRangeToStepsConverter(50),
      // );
      // const ballStrikesColorConverter = new Converters.RangeToColorConverters.SymmetricRangeToColorConverter(
      //   new Converters.RangeToColorConverters.LinearRangeToColorConverter(new ValueObjects.RGBA(250, 0, 0, 0), new ValueObjects.RGBA(250, 0, 0, 255)),
      //   new Converters.RangeToColorConverters.LinearRangeToColorConverter(new ValueObjects.RGBA(0, 250, 0, 0), new ValueObjects.RGBA(0, 250, 0, 255))
      // );
      // const rangeToColorConverter = new Converters.RangeToColorConverters.ConditionalRangeToColorConverter(-0, 0, new ValueObjects.RGBA(0,0,0,0), ballStrikesColorConverter);

      // const colorSteps = Providers.HeatMapColorStepsProvider(series, ballStrikesStepsConverter, rangeToColorConverter, Enums.SeriesDimension.Y);

      const colorConverter = new Converters.RangeToColorConverters.LinearRangeToColorConverter(new ValueObjects.RGBA(0, 0, 255, 255), new ValueObjects.RGBA(255, 0, 0, 255));
      const colorsPerSector = Providers.HeatMapColorsPerSectorProvider(series, colorConverter, Enums.SeriesDimension.Y);

      return colorsPerSector;
    },
  },

  mounted() {
    const chartSeries = this.initialSeries();

    const [colorSteps] = this.colorSteps(chartSeries);

    const [series] = chartSeries;

    this.mappedValues = colorSteps.map((step, i) => {
      return [...series[i], step];
    });
  },
};
</script>

<style>
</style>
