export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    initialize({ dispatch }) {
      console.info('uiExamples initializing...');
      console.info('uiExamples initialized.');
    },
  },
};
