import dataTableColumnsProvider from '../providers/DataTableColumnsProvider';
import { concatAndDeDuplicate } from '../utils/helpers';

export default class DataTableColumnsWrapper {
  constructor(
    {
      hasNoHittingData,
      hasNoPitchingData,
      mightHaveRadarData,
      mightHaveScoring,
      mightHaveNewScoringData,
      sessionSchemaVer,
      mightHaveTagging
    } =
    {
      hasNoHittingData: true,
      hasNoPitchingData: true,
      mightHaveRadarData: true,
      mightHaveScoring: false,
      mightHaveNewScoringData: false,
      sessionSchemaVer: 1,
      mightHaveTagging: true,
    }
  ) {
    this.dataTableColumnsProvider = dataTableColumnsProvider;
    this.hasNoHittingData = hasNoHittingData;
    this.hasNoPitchingData = hasNoPitchingData;
    this.mightHaveRadarData = mightHaveRadarData;
    this.mightHaveScoring = mightHaveScoring;
    this.mightHaveNewScoringData = mightHaveNewScoringData;
    this.sessionSchemaVer = sessionSchemaVer;
    this.mightHaveTagging = mightHaveTagging;
  }

  // Returns available columns set according to session type. I.e. for session having pitching data.
  getIndexesOfAvailableColumns() {
    let indexes = [...this.dataTableColumnsProvider.commonColumnsIndexes];
    if (!this.hasNoHittingData) {
      indexes = concatAndDeDuplicate(indexes, this.dataTableColumnsProvider.hitColumnsIndexes);
    }

    if (!this.hasNoPitchingData) {
      indexes = concatAndDeDuplicate(indexes, this.dataTableColumnsProvider.pitchColumnsIndexes);
    }

    if (this.mightHaveScoring) {
      indexes = concatAndDeDuplicate(indexes, this.dataTableColumnsProvider.scoreColumnsIndexes);
    }

    if (!this.mightHaveRadarData) {
      indexes = indexes.filter((index) => !this.dataTableColumnsProvider.getIndexesOfRadarColumns().includes(index));
    }

    if (this.mightHaveNewScoringData) {
      indexes = concatAndDeDuplicate(indexes, this.dataTableColumnsProvider.getIndexesOfNewScoreColumns());
    }

    if (this.sessionSchemaVer === 2) {
      indexes = indexes.filter((index) => !this.dataTableColumnsProvider.getIndexesOfV2ExcludedColumns().includes(index));
    }

    if (!this.mightHaveTagging) {
      indexes = indexes.filter((index) => !this.dataTableColumnsProvider.getIndexesOfTaggingColumns().includes(index));
    }

    // remove empty indexes
    indexes = indexes.filter((index) => index);

    // preserve order
    const indexesInOrder = [];
    this.dataTableColumnsProvider.knownColumns.forEach((column, key) => {
      if (column && indexes.indexOf(key) > -1) {
        indexesInOrder.push(key);
      }
    });

    return indexesInOrder;
  }

  // returns all column indexes visible by default
  getIndexesOfDefaultColumns() {
    return this.dataTableColumnsProvider.getIndexesOfDefaultColumns();
  }

  // returns all column indexes visible in current type of session (available pitch/hit/scoring)
  getIndexesOfVisibleColumns(selectedColumnsIndexes) {
    if (!Array.isArray(selectedColumnsIndexes)) {
      return [];
    }

    return this.getIndexesOfAvailableColumns().filter((id) => {
      // return only these columns which are included in selected by default in user store
      return selectedColumnsIndexes.includes(id);
    });
  }

  // gets single specific column from known columns defined in DataTableColumnsProvider
  getColumn(index) {
    return this.dataTableColumnsProvider.getColumn(index);
  }

  getColumns(...indexes) {
    return this.dataTableColumnsProvider.getColumns(...indexes);
  }

  // get all column indexes
  getAllColumns() {
    return this.dataTableColumnsProvider.getAllColumns();
  }
}
