import merge from 'lodash/merge';
import PlotlyGraph from '@/components/ui/charts/plotly/PlotlyGraph.vue';
import { Layouts, Configs } from '@/components/ui/charts/plotly/PlotlyHelpers';

const axisSettings = [
  {
    key: 'xaxis.rangemode',
    label: 'X Axis Range Mode',
    options: ['normal', 'tozero', 'nonnegative'],
    defVal: 'normal',
  },
  {
    key: 'xaxis.constrain',
    label: 'X Axis Constrain',
    options: ['range', 'domain'],
    defVal: 'range'
  },
  {
    key: 'xaxis.constraintoward',
    label: 'X Axis Constrain Toward',
    options: ['left', 'center', 'right'],
    defVal: 'center',
  },

  {
    key: 'yaxis.rangemode',
    label: 'Y Axis Range Mode',
    options: ['normal', 'tozero', 'nonnegative'],
    defVal: 'normal',
  },
  {
    key: 'yaxis.constrain',
    label: 'Y Axis Constrain',
    options: ['range', 'domain'],
    defVal: 'domain',
  },
  {
    key: 'yaxis.constraintoward',
    label: 'Y Axis Constrain Toward',
    options: ['top', 'middle', 'bottom'],
    defVal: 'middle',
  },
];

export default {
  name: 'PlotlySprayChartExample',

  components: {
    PlotlyGraph,
  },

  props: {
    data: {
      type: Array,
    },
  },

  data() {
    return {
      layout: {
        ...Layouts.base,
        ...Layouts.sprayChart,
      },
      config: {
        ...Configs.base,
      },
      editableSettings: axisSettings
        .map(({ key, defVal }) => ({ key, defVal }))
        .reduce((result, item, index) => {
          result[item.key] = item.defVal;
          return result;
        }, {}),
    };
  },

  computed: {
    settings() {
      return axisSettings;
    },
    changedSettings() {
      return {
        xaxis: {
          rangemode: this.editableSettings['xaxis.rangemode'],
          constrain: this.editableSettings['xaxis.constrain'],
          constraintoward: this.editableSettings['xaxis.constraintoward'],
        },
        yaxis: {
          rangemode: this.editableSettings['yaxis.rangemode'],
          constrain: this.editableSettings['yaxis.constrain'],
          constraintoward: this.editableSettings['yaxis.constraintoward'],
        },
      };
    },
    innerLayout() {
      return merge({}, this.layout, { xaxis: { title: 'X' }, yaxis: { title: 'Y' } }, this.changedSettings);
    },
    innerConfig() {
      return this.config;
    },
  },
};
