const HBarTrace = {
  orientation: 'h',
  textposition: 'auto',
  hoverinfo: 'none',
  texttemplate: '%{x:.2f}',
};

const ScatterTrace = {
  type: 'scatter',
  mode: 'markers',
  hoverinfo: 'all',
  marker: { size: 8, opacity: 0.7, line: { width: 0.5 } },
};

const TrackingTrace = {
  type: 'scatter',
  mode: 'markers',
  hoverinfo: 'all',
  line: { color: 'rgba(0,143,251,0.1)', width: 4 },
  marker: { color: 'rgb(0, 143, 251)', width: 6 },
};

const MovingAvgTrace = {
  mode: 'none',
  fill: 'toself',
  fillcolor: 'rgba(255,0,0,0.3)',
};

export default { HBarTrace, ScatterTrace, TrackingTrace, MovingAvgTrace };
