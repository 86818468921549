const sprayChartHoverTemplate = `<b>%{text}</b><br><br>
%{yaxis.title.text}: %{y:.0f}<br>
%{xaxis.title.text}: %{x:.0f}`;

const sprayChartHoverTemplateExtra = `<b>%{text}</b><br><br>
%{yaxis.title.text}: %{y:.0f}<br>
%{xaxis.title.text}: %{x:.0f}<br>
<extra></extra>`;

export default {
  sprayChartHoverTemplate,
  sprayChartHoverTemplateExtra,
};
