import Images from './images';
import Traces from './traces';
import Layouts from './layouts';
import Configs from './configs';
import Mappers from './mappers';
import Options from './options';

const SeriesFields = Object.freeze({
  X: 'x',
  Y: 'y',
  Z: 'z',
  R: 'r',
  B: 'b',
  Theta: 'theta',
});

export { Images, Layouts, Configs, Traces, Mappers, Options, SeriesFields };
