<template>
  <v-dialog
    v-model="open"
    persistent
    max-height="785"
    max-width="1136"
  >

  <multiple-checkbox-card
    v-model="selected"
    :title="messages.TITLE"
    :options="options"
    :loading="loading"
  >
    <template v-slot:toolbar-actions>
      <v-btn
        color="primary"
        :disabled="selected.length == options.length"
        text
        class="right"
        @click="selectAll"
      >{{ messages.BTN_SELECT_ALL }}</v-btn>
      <v-btn
        color="primary"
        :disabled="hasNoSelection"
        text
        class="right"
        @click="clear"
      >{{ messages.BTN_CLEAR }}</v-btn>
      <v-btn
        :disabled="areEqualWithDefaults"
        color="primary"
        text
        class="right"
        @click="restore"
      >{{ messages.BTN_RESTORE }}</v-btn>
    </template>

    <template v-slot:card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="cancel">{{ messages.BTN_CANCEL }}</v-btn>
      <v-btn color="primary"
        :disabled="hasNoSelection || areEqual"
        text
        @click="save"
      >{{ messages.BTN_SAVE }}</v-btn>
    </template>
  </multiple-checkbox-card>
  </v-dialog>
</template>

<script>
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import { dispatch } from 'vuex-pathify';
import MultipleCheckboxCard from '../selection-controls/MultipleCheckboxCard.vue';
import DataTableColumnsWrapper from '../../../models/DataTableColumnsWrapper';
import { SET_USER_CONFIG } from '../../../store/actions/user';

const MSG = {
  TITLE: 'Table Column Settings',
  BTN_RESTORE: 'Restore default',
  BTN_SELECT_ALL: 'All',
  BTN_CLEAR: 'Clear',
  BTN_SAVE: 'Save',
  BTN_CANCEL: 'Cancel',
};

const dataTableColumnsWrapper = new DataTableColumnsWrapper();

export default {
  name: 'TableColumnsDialog',
  // mixins: [FilterInStore],
  components: {
    MultipleCheckboxCard,
  },
  props: {
    storeName: {
      type: String,
      default: 'user@config',
    },
    storeKey: {
      type: String,
      default: 'dataTableColumns',
    },
    indexesOfAvailableColumns: {
      type: Array,
      default: () => [],
    },
    defaultSelected: {
      type: Array,
      default: () => dataTableColumnsWrapper.getIndexesOfDefaultColumns(),
    },
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: ['openDialog'],
    event: 'input',
  },
  data() {
    return {
      messages: MSG,
      debug: true,
      selected: [],
      loading: true,
    };
  },
  computed: {
    open: {
      get() {
        return this.openDialog || false;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    storePath() {
      return `${this.storeName}.${this.storeKey}`;
    },
    saved: {
      get() {
        return this.$store.get(this.storePath);
      },
      set(value) {
        dispatch(SET_USER_CONFIG, { key: this.storeKey, value });
      },
    },
    areEqual() {
      return isEqual(this.saved, sortBy(this.selected));
    },
    areEqualWithDefaults() {
      return isEqual(this.defaultSelected, sortBy(this.selected));
    },
    hasNoSelection() {
      return this.selected.length === 0;
    },
    options() {
      return dataTableColumnsWrapper.getColumns(...this.indexesOfAvailableColumns);
    },
  },
  methods: {
    watchSaved(value) {
      if (isArray(value) && value.length) {
        this.selected = value;
        this.loading = false;
      }
    },
    save() {
      if (!this.areEqual) {
        this.saved = sortBy(this.selected);
      }
      this.open = false;
    },
    cancel() {
      this.selected = this.saved;
      this.open = false;
    },
    restore() {
      this.selected = this.defaultSelected;
    },
    clear() {
      this.selected = [];
    },
    selectAll() {
      this.selected = [...this.indexesOfAvailableColumns];
    },
  },
  watch: {
    saved: {
      handler: 'watchSaved',
      immediate: true,
    },
  },
};
</script>

<style>
.table-columns-selector {
  max-height: 700px;
}
</style>
