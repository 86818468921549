<template>
  <v-menu
    ref="datepicker"
    v-model="datepicker"
    :close-on-content-click="false"
    :return-value.sync="selected"
    transition="scale-transition"
    :disabled="disabled"
    min-width="219"
    offset-y>
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="dateRangeText"
        label="Date range"
        append-icon="$vuetify.icons.calendar"
        readonly
        outlined
        dense
        hide-details
        :disabled="disabled"
        v-on="on"
        @click:clear="clear"
      >
      </v-text-field>
    </template>
    <v-date-picker
      v-model="selected"
      :reactive="true"
      :min="rangeMinimum"
      :max="rangeMaximum"
      header-color="primary"
      color="primary"
      range
      no-title
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn
        text color="primary"
        @click="clear"
        :disabled="selected.length == 0">Clear</v-btn>
      <v-btn
        text color="primary"
        @click="datepicker = false">Cancel</v-btn>
      <v-btn
        text color="primary"
        @click="clickOk"
        :disabled="selected === stateSelected">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';
import { get } from 'vuex-pathify';
import { SET_DATES } from '../../store/actions/sessions';
import { DATE_FMT } from '../../utils/date_n_time';

export default {
  name: 'DateRangeFilter',
  props: {
    module: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      datepicker: false,
      selected: [],
    };
  },
  computed: {
    season: get('app/filters.season'),

    disabled() {
      return this.$store.state.sessions[this.module].filter.status !== 'SUCCESS';
    },
    stateSelected: {
      // TODO: Maybe the getter/setter should use local date on display
      get() {
        return this.$store.getters.datesSorted(this.module);
      },
      set(value) {
        this.$store.dispatch(SET_DATES, { module: this.module, value });
      },
    },
    currentYear() {
      return moment([this.season.startDate]);
    },
    yearStart() {
      const start = moment(this.season.startDate);
      return start;
    },
    yearEnd() {
      const end = moment(this.season.endDate);
      return end;
    },
    rangeMinimum() {
      return this.yearStart.format(DATE_FMT.DATE);
    },
    rangeMaximum() {
      return this.yearEnd.format(DATE_FMT.DATE);
    },
    pickerDate() {
      return this.currentYear.format(DATE_FMT.YEAR);
    },
    dateRangeText() {
      if (this.selected.length > 0) {
        return [...this.selected].sort().join(' ~ ');
      }
      return `All in ${this.season.displayName}`;
    },
  },
  methods: {
    clear() {
      this.selected = [];
    },
    clickOk() {
      this.stateSelected = this.selected;
      this.$refs.datepicker.save(this.selected);
    },
    watchStateSelected(val, oldVal) {
      this.selected = val;
    },
  },
  watch: {
    stateSelected: {
      handler: 'watchStateSelected',
      immediate: true,
    },
  },
};
</script>
