import merge from 'lodash/merge';
import { GenericStrikeZone } from '@/enums/Generic';
import { POV, ZONES, magnify, toZone, scalePoints, pointToSvgPoint } from './strikeZoneHelpers';


export default {
  name: 'StrikeZoneHmap',

  props: {
    // [ [sector. value. RGBA] ]
    value: {
      type: Array,
      required: true,
    },

    // TODO: maybe add value type

    valueFormatter: {
      type: Function,
      default: (val) => val,
    },

    /**
     * Point Of View
     */
    pov: {
      type: Number,
      default: POV.BATTER,
    },

    title: { type: String, default: '' },
    description: { type: String, default: '' },
  },

  data() {
    return {
      box: new GenericStrikeZone(),
      image: {
        outer: {
          width: 600,
          height: 600,
        },
        inner: {
          width: 500,
          height: 500,
        },
      },
      sectorGrid: {
        x: 3,
        y: 3,
      },

      viewBox: {
        default: '0 0 500 500',
      },

      sectors: {},

      zones: [],
      mappedZones: [],

      zeroPoints: [],
    };
  },

  // {
  //   "maxHorizontalOffset": 1000,
  //   "maxVerticalOffset": 1000,
  //   "ballDiameter": 0.07619999999999999,
  //   "strikeZoneLeft": -0.21589999999999998,
  //   "strikeZoneRight": 0.21589999999999998,
  //   "strikeZoneTop": 1.0668,
  //   "strikeZoneBottom": 0.4572
  // }
  computed: {
    strikeZoneHeight() {
      return this.box.strikeZoneTop - this.box.strikeZoneBottom;
    },

    strikeZoneWidth() {
      return this.box.strikeZoneRight - this.box.strikeZoneLeft;
    },

    sectorWidth() {
      return this.strikeZoneWidth / this.sectorGrid.x;
    },

    sectorHeight() {
      return this.strikeZoneHeight / this.sectorGrid.y;
    },

    strikeZoneCenterY() {
      return this.box.strikeZoneTop - this.strikeZoneHeight / 2;
    },

    lines() {
      let leftX = this.box.strikeZoneLeft - this.sectorWidth;
      let rightX = this.box.strikeZoneRight + this.sectorWidth;
      let topY = this.box.strikeZoneTop + this.sectorHeight;
      let bottomY = this.box.strikeZoneBottom - this.sectorHeight;

      return { leftX, rightX, topY, bottomY };
    },

    /**
     * Collection of points
     *
     *  o1____________o2_____________o3
     *   |             |             |
     *   |  ze_______o4|_________    |
     *   |  ro|   i2|   i3|   i4|    |
     *   |    |     |     |     |    |
     *   |    |     |     |     |    |
     *   |    |_____|_____|_____|    |
     *   |  i5|   i6|   i7|   i8|    |
     * o5|____|o6   |     |   o7|____|o8
     *   |    |     |     |     |    |
     *   |    |_____|_____|_____|    |
     *   |  i9|  i10|  i11|  i12|    |
     *   |    |     |     |     |    |
     *   |    |     |     |     |    |
     *   |    |_____|o9___|_____|    |
     *   |  i13   i14  |i15   i17    |
     *   |_____________|_____________|
     *  o10           o11            o12
     */
    points() {
      let o1 = [this.lines.leftX, this.lines.topY];
      let o2 = [0, this.lines.topY];
      let o3 = [this.lines.rightX, this.lines.topY];
      let o4 = [0, this.box.strikeZoneTop];

      let o5 = [this.lines.leftX, this.strikeZoneCenterY];
      let o6 = [this.box.strikeZoneLeft, this.strikeZoneCenterY];
      let o7 = [this.box.strikeZoneRight, this.strikeZoneCenterY];
      let o8 = [this.lines.rightX, this.strikeZoneCenterY];

      let o9 = [0, this.box.strikeZoneBottom];

      let o10 = [this.lines.leftX, this.lines.bottomY];
      let o11 = [0, this.lines.bottomY];
      let o12 = [this.lines.rightX, this.lines.bottomY];

      let zero = [this.box.strikeZoneLeft, this.box.strikeZoneTop];

      let i4 = [this.box.strikeZoneRight, this.box.strikeZoneTop];
      let i13 = [this.box.strikeZoneLeft, this.box.strikeZoneBottom];
      let i17 = [this.box.strikeZoneRight, this.box.strikeZoneBottom];

      return {
        o1,
        o2,
        o3,
        o4,
        o5,
        o6,
        o7,
        o8,
        o9,
        o10,
        o11,
        o12,
        zero,
        i4,
        i13,
        i17,
      };
    },

    svgProps() {
      let margin = 1.2;
      let height = magnify((this.lines.topY + 0.5) * margin);
      return {
        width: this.image.width,
        height: this.image.height,
        viewBox: `${height / -2} ${(height - 80) * -1} ${height} ${height}`,
      };
    },

    sectorTL() {
      let { o1, o2, o4, zero, o5, o6 } = this.points;
      return [o1, o2, o4, zero, o6, o5];
    },

    sectorTR() {
      let { o2, o3, o4, o7, o8, i4 } = this.points;
      return [o2, o3, o8, o7, i4, o4];
    },

    sectorBL() {
      let { o5, o6, i13, o9, o11, o10 } = this.points;
      return [o5, o6, i13, o9, o11, o10];
    },

    sectorBR() {
      let { o7, o8, o12, o11, o9, i17 } = this.points;
      return [o7, o8, o12, o11, o9, i17];
    },

    povClass() {
      if (this.pov === POV.BATTER) {
        return 'pov-batter';
      }
      if (this.pov === POV.PITCHER) {
        return 'pov-pitcher';
      }
      return '';
    },
  },

  methods: {
    mapDataToSectors() {
      if (!this.value.length) {
        return {};
      }
      let mapped = this.value.map(([sector, value, color]) => ({ [sector]: { value, color } }));
      let merged = merge(...mapped);
      return merged;
    },

    magnify,

    innerSector(row, col) {
      let p1 = [this.points.zero[0] + this.sectorWidth * col, this.points.zero[1] - this.sectorHeight * row];

      this.zeroPoints.push(p1);

      let p2 = [p1[0] + this.sectorWidth, p1[1]];
      let p3 = [p1[0], p1[1] - this.sectorHeight];
      let p4 = [p2[0], p3[1]];

      return [p1, p2, p4, p3];
    },

    getZones() {
      // TODO: map sectors to zones depending on POV
      // outer
      this.zones.push([11, this.sectors[11], this.sectorTL, ZONES.OUTER.TOP.LEFT]);
      this.zones.push([12, this.sectors[12], this.sectorTR, ZONES.OUTER.TOP.RIGHT]);
      this.zones.push([13, this.sectors[13], this.sectorBL, ZONES.OUTER.BOTTOM.LEFT]);
      this.zones.push([14, this.sectors[14], this.sectorBR, ZONES.OUTER.BOTTOM.RIGHT]);

      // inner
      let row = 0;
      let col = 0;
      let points = [];
      for (let i = 1; i < 10; i++) {
        points = this.innerSector(row, col);
        this.zones.push([i, this.sectors[i], points]);
        col++;
        if (!(i % 3)) {
          col = 0;
          row++;
        }
      }
    },

    homePlateShape() {
      let points = [
        [this.box.strikeZoneLeft, 0],
        [this.box.strikeZoneRight, 0],
        [this.box.strikeZoneRight * 1.3, -0.15],
        [0, -0.3],
        [this.box.strikeZoneLeft * 1.3, -0.15],
        [this.box.strikeZoneLeft, 0],
      ];
      let svgPoints = points
        .map(scalePoints)
        .map(pointToSvgPoint)
        .filter((point) => point);
      let d = svgPoints.join(' ');
      return d;
    },

    handler(val) {
      if (!val.length) {
        return;
      }
      this.zones = [];
      this.sectors = this.mapDataToSectors();

      this.getZones();

      this.mappedZones = this.zones.map(toZone).filter((zone) => zone);
    },
  },

  watch: {
    value: {
      handler: 'handler',
      immediate: true
    },
  },
};
