export const SESSION_REQUEST = 'SESSION_REQUEST';
export const SESSION_SUCCESS = 'SESSION_SUCCESS';
export const SESSION_ERROR = 'SESSION_ERROR';

export const SET_SESSION_FILTERS = 'SET_SESSION_FILTERS';
export const SET_PITCHING_SUMMARY_FILTERS = 'SET_PITCHING_SUMMARY_FILTERS';
export const SET_PITCHING_POST_GAME_FILTERS = 'SET_PITCHING_POST_GAME_FILTERS';
export const SET_BATTING_POST_GAME_FILTERS = 'SET_BATTING_POST_GAME_FILTERS';
export const SET_RELEASE_POINT_FILTERS = 'SET_RELEASE_POINT_FILTERS';
export const SET_RELEASE_EXTENSION_FILTERS = 'SET_RELEASE_EXTENSION_FILTERS';
export const SET_PITCH_TRACKING_FILTERS = 'SET_PITCH_TRACKING_FILTERS';
export const SET_PITCH_MOVEMENT_FILTERS = 'SET_PITCH_MOVEMENT_FILTERS';
export const SET_LOCATION_CHART_FILTERS = 'SET_LOCATION_CHART_FILTERS';
export const SET_DATA_TABLE_FILTERS = 'SET_DATA_TABLE_FILTERS';
export const CLEAR_DATA_TABLE_FILTERS = 'CLEAR_DATA_TABLE_FILTERS';
export const SET_HITTING_SUMMARY_FILTERS = 'SET_HITTING_SUMMARY_FILTERS';
export const SET_STRIKEZONE_CHART_FILTERS = 'SET_STRIKEZONE_CHART_FILTERS';
export const SET_HIT_SPRAY_CHART_FILTERS = 'SET_HIT_SPRAY_CHART_FILTERS';
export const SET_HIT_TRACKING_FILTERS = 'SET_HIT_TRACKING_FILTERS';
export const DELETE_RESULTS = 'DELETE_RESULTS';
