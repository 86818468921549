<template>
<table-columns-dialog></table-columns-dialog>
</template>

<script>
import TableColumnsDialog from '@/components/ui/dialogs/TableColumnsDialog.vue';

export default {
  name: 'DataTableControls',
};
</script>
