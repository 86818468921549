function removeNonPlotlySeries({ key, name }) {
  if (key == 'hiddenSeries') {
    return false;
  }

  return true;
}

function mapToPlotly({ key, name, data, type = 'scatter', mode = 'markers', color = 'blue' }, index, collection) {
  if (key == 'foulLine' || key == 'grassLine' || key == 'strikeZoneSectors' || key == 'strikeZone') {
    type = 'scatter';
    mode = 'lines';
  }

  let plotlyTrace = {
    key,
    name,
    type,
    mode,
    color,
    x: [],
    y: [],
  };

  for (let i = 0; i < data.length; i++) {
    plotlyTrace.x.push(data[i].x);
    plotlyTrace.y.push(data[i].y);
  }

  return plotlyTrace;
}

export default { removeNonPlotlySeries, mapToPlotly };
