<template>
  <v-container>
<v-row>
  <v-col  cols="6">

    <v-text-field v-model="dateString"></v-text-field>
    <v-combobox
      v-model="dateType"
      :items="types"
      label="Format string"
    ></v-combobox>
    <!-- <v-checkbox v-model="isUtc" label="Is UTC ?"></v-checkbox> -->

    <pre><code>
    Input: {{ dateString }} | utcToLocal()
    Output without format: {{ dateString | utcToLocal }}
    Output with format: {{ dateString | utcToLocal(dateType) }}
    </code></pre>
  </v-col>
  <v-col cols="6">
    <date-range-filter module="games" />
  </v-col>
</v-row>

  </v-container>
</template>

<script>
import { HTML5_FMT } from 'moment';
import { DATE_FMT } from '@/utils/date_n_time';
import DateRangeFilter from '@/components/filters/DateRangeFilter.vue';

export default {
  name: 'TimeHandlingExample',
  components: {
    DateRangeFilter,
  },
  data() {
    return {
      dateString: '2020-01-17 11:43:00',
      isUtc: true,
      dateType: undefined,
      types: [
        { text: 'Undefined' },
        { value: HTML5_FMT.DATE, text: HTML5_FMT.DATE },
        { value: HTML5_FMT.TIME, text: HTML5_FMT.TIME },
        { value: DATE_FMT.DATETIME_SECONDS, text: DATE_FMT.DATETIME_SECONDS },
        { value: 'dateTime', text: 'dateTime' },
        { value: 'time', text: 'time' },
        { value: 'date', text: 'date' },
        { value: 'gibberish', text: 'gibberish' },
        { value: '', text: 'Empty string' },
      ],
    };
  },
};
</script>
