<template>
  <v-card>
    <v-toolbar flat v-if="title" class="toolbar">
      <v-toolbar-title class="body-1 mine-shaft--text">{{ title }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <slot name="toolbar-actions"></slot>
    </v-toolbar>
    <slot></slot>
    <v-divider></v-divider>
    <div class="table-columns-selector d-flex flex-column flex-wrap align-content-start px-6">
      <v-checkbox
        v-for="({ text, key }, i) in options"
        :key="key"
        v-model="selected"
        :true-value="i"
        :value="key"
        :label="text"
        multiple
        dense
        hide-details
        class="mt-2"
        color="primary"
      ></v-checkbox>
    </div>
    <v-card-actions>
      <slot name="card-actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss">
.toolbar ::v-deep .v-toolbar__content {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
</style>

<script>
import Selectable from '../../mixins/Selectable';

export default {
  name: 'MultipleCheckboxCard',
  mixins: [Selectable],
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
