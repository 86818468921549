import uiExamples from './uiExamples';

export default [
  {
    path: '/ui',
    name: 'ui',
    component: () => import('@/views/SimpleView.vue'),
    children: uiExamples,
    redirect: {
      name: 'ui.selectionControls',
    },
    props: {
      routes: uiExamples.map(({ name }) => ({ name })),
    },
  },
];
