<script>
import omit from 'lodash/omit';
import values from 'lodash/values';
import { Enums } from '@flightscope/baseball-stats';
import { mapOptionsToSelect } from '@/utils/mappers';
// import MultipleCheckboxCard from '../selection-controls/MultipleCheckboxCard.vue';
import CheckboxGroupDropdown from '@/components/ui/dropdowns/CheckboxGroupDropdown.vue';
import CheckboxGridDropdown from '@/components/ui/dropdowns/CheckboxGridDropdown.vue';
import Example from './Example.vue';
// import CheckboxGridPicker from '../pickers/CheckboxGridPicker.vue';
// import CheckboxGroupPicker from '../pickers/CheckboxGroupPicker.vue';

const checkboxGroups = [
  {
    title: 'Breaking',
    color: 'cyan',
    all: true,
    items: [
      { text: 'Slider', value: 'SL' },
      { text: 'Curveball', value: 'CU' },
      { text: 'Knuckleball', value: 'KN' },
    ],
  },
  {
    title: 'Offspeed',
    color: 'green',
    all: true,
    items: [
      { text: 'Splitter', value: 'SP' },
      { text: 'Changeup', value: 'CH' },
    ],
  },
  {
    title: 'Fastball',
    color: 'red',
    all: true,
    items: [
      { text: 'Cutter', value: 'CT' },
      { text: 'Sinker', value: 'SI' },
    ],
  },
  {
    title: 'Ungrouped',
    all: true,
    items: [
      { text: 'Dirtball', value: 'DB' },
      { text: 'Drop Ball', value: 'DR' },
    ],
  },
];

export default {
  name: 'SelectionControlsExample',
  props: [],
  components: {
    Example,
    // MultipleCheckboxCard,
    CheckboxGroupDropdown,
    CheckboxGridDropdown,
    // CheckboxGridPicker,
  },
  data() {
    return {
      groups: checkboxGroups,
      selected: [
        'CU', 'KN',
      ],
      selectedPitchResults: [
        'DE', 'IB', 'CO',
      ],
      disabledOptions: ['E', 'CH'],
      items: ['checkbox-group-dropdown', 'pitch-result-dropdown'],
      selectedComponent: 'pitch-result-dropdown',
    };
  },
  computed: {
    optionsPitchResults() {
      return mapOptionsToSelect('pitch-result');
    },
    filteredOptions() {
      return this.optionsPitchResults.map((option) => {
        if (this.disabledOptions.includes( option.value ) ) {
          option.disabled = true;
        }
        return option;
      });
    },
    actionGroups() {
      return values(omit(Enums.PitchResultGroup, 'all'))
        .map(({ name, results }) => ({ text: name, items: results }));
    },
    displaySelected() {
      switch (this.selectedComponent) {
        case 'pitch-result-dropdown':
          return this.selectedPitchResults;
        default:
          return this.selected;
      }
    },
  },
};
</script>

<template>
  <example>

    <template v-slot:options>
      <v-select
        :items="items"
        v-model="selectedComponent"
        label="Select Component to test"
      ></v-select>
    </template>

    <template>

      <v-container fluid fill-height>
        <v-row>
          <v-col class="d-flex" cols="6" >
            <checkbox-group-dropdown
              v-if="selectedComponent === 'checkbox-group-dropdown'"
              v-model="selected"
              :options="groups" />

            <checkbox-grid-dropdown
              v-if="selectedComponent === 'pitch-result-dropdown'"
              v-model="selectedPitchResults"
              :options="filteredOptions"
              :action-groups="actionGroups"
              clearable
              select-all
            />

          </v-col>
          <v-col class="d-flex" cols="6" >
            <pre><code>{{ displaySelected }}</code></pre>
          </v-col>
        </v-row>
      </v-container>

    </template>

  </example>
</template>
