const ModeBarButtons = Object.freeze({
  TwoD: {
    zoom2d: 'zoom2d',
    pan2d: 'pan2d',
    select2d: 'select2d',
    lasso2d: 'lasso2d',
    zoomIn2d: 'zoomIn2d',
    zoomOut2d: 'zoomOut2d',
    autoScale2d: 'autoScale2d',
    resetScale2d: 'resetScale2d',
  },
  ThreeD: {
    zoom3d: 'zoom3d',
    pan3d: 'pan3d',
    orbitRotation: 'orbitRotation',
    tableRotation: 'tableRotation',
    handleDrag3d: 'handleDrag3d',
    resetCameraDefault3d: 'resetCameraDefault3d',
    resetCameraLastSave3d: 'resetCameraLastSave3d',
    hoverClosest3d: 'hoverClosest3d',
  },
  Cartesian: {
    hoverClosestCartesian: 'hoverClosestCartesian',
    hoverCompareCartesian: 'hoverCompareCartesian',
  },
  Geo: {
    zoomInGeo: 'zoomInGeo',
    zoomOutGeo: 'zoomOutGeo',
    resetGeo: 'resetGeo',
    hoverClosestGeo: 'hoverClosestGeo',
  },
  Other: {
    hoverClosestGl2d: 'hoverClosestGl2d',
    hoverClosestPie: 'hoverClosestPie',
    toggleHover: 'toggleHover',
    resetViews: 'resetViews',
    toImage: 'toImage',
    sendDataToCloud: 'sendDataToCloud',
    toggleSpikelines: 'toggleSpikelines',
    resetViewMapbox: 'resetViewMapbox',
  },
});

const plotlyConfig = {
  // responsive: true,
  scrollZoom: false,
  displaylogo: false,
  showTips: false,
  modeBarButtonsToRemove: [
    ModeBarButtons.TwoD.select2d,
    ModeBarButtons.TwoD.lasso2d,
    ModeBarButtons.TwoD.autoScale2d,
    ModeBarButtons.TwoD.zoom2d,
    ModeBarButtons.Cartesian.hoverCompareCartesian,
  ],
  // customized download, @see https://plotly.com/javascript/configuration-options/#customize-download-plot-options
  toImageButtonOptions: {
    format: 'png', // one of png, svg, jpeg, webp
    filename: 'custom_chart',
    height: null,
    width: null,
    scale: 2, // Multiply title/legend/axis/canvas sizes by this factor
  },
};

const barChartConfig = {
  modeBarButtonsToRemove: [
    ModeBarButtons.TwoD.select2d,
    ModeBarButtons.TwoD.lasso2d,
    ModeBarButtons.TwoD.autoScale2d,
    ModeBarButtons.TwoD.zoom2d,
    ModeBarButtons.TwoD.zoomIn2d,
    ModeBarButtons.TwoD.zoomOut2d,
    ModeBarButtons.TwoD.resetScale2d,
    ModeBarButtons.Cartesian.hoverCompareCartesian,
    ModeBarButtons.Cartesian.hoverClosestCartesian,
    ModeBarButtons.Other.toggleSpikelines,
  ],
};

export default {
  base: plotlyConfig,
  bar: barChartConfig,
};
