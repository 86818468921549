import { mapGetters, mapState } from 'vuex';
import { Providers } from '@flightscope/baseball-stats';
import {Session} from '@/models/orm/Hierarchy';
import sessionResultsFormatter from '@/formatters/sessionResultsFormatter';
import PlotlyGraph from '@/components/ui/charts/plotly/PlotlyGraph.vue';
import { Traces, Configs, Layouts, Mappers } from '@/components/ui/charts/plotly/PlotlyHelpers';
import { SESSION_REQUEST } from '@/store/actions/session';
import { ROSTER_REQUEST } from '@/store/actions/roster';
import Example from './Example.vue';
import PlotlySprayChartExample from './PlotlySprayChartExample.vue';
import { captureException } from '../../plugins/sentry';

export default {
  name: 'PlotlyExamples',
  components: {
    Example,
    PlotlyGraph,
    PlotlySprayChartExample,
  },
  data() {
    return {
      loading: false,
      tab: 'tab-3',
      linePlotData: [Traces.trace1],
      scatterPlotData: [Traces.foulLineTraceSI, Traces.grassLineTraceSI, { ...Traces.trace2, hovertemplate: Layouts.hoverTemplates.sprayChartHoverTemplate }],
      scatterPlotDataWith1point: [Traces.trace2uno],
      scatterPlotDataNegative: [Traces.trace2uno],
      heatmapPlotData: [Traces.trace3],

      sprayChartData: [],

      mixedPlotData: [Traces.trace1, Traces.trace2, Traces.trace3],

      sessionsList: [126, 96, 93],

      selectedSession: 96,
      session: null,

      currentVal: 0,

      plotlyLayout: Layouts.base,
      plotlyConfig: Configs.base,
      sprayChartLayout: Layouts.sprayChart,
    };
  },

  computed: {
    ...mapGetters([
      'isLeagueManagerForCurrentSeason',
      'isProfileLoaded',
      'rosterByTeamId',
      'playerOrTag',
      'selectedUnitsSystem',
      'allTeamsLoaded',
      'authStatus',
    ]),

    ...mapState({
      sessionData(state) {
        if (
          this.selectedSession
          && typeof state.session[this.selectedSession] !== 'undefined'
          && state.session[this.selectedSession].data
        ) {
          return state.session[this.selectedSession].data;
        }

        return null;
      },
    }),

    sessionAvailable() {
      return this.session instanceof Session && this.session.id > 0;
    },

    hasSessionData() {
      return this.selectedSession && this.sessionAvailable;
    },

    sessionDataAvailable() {
      return this.sessionData !== null;
    },
    homeTeamDataAvailable() {
      return this.homeTeamData !== null;
    },
    awayTeamDataAvailable() {
      return this.awayTeamData !== null;
    },
    homeTeamRosterAvailable() {
      return this.homeTeamRoster !== null;
    },
    awayTeamRosterAvailable() {
      return this.awayTeamRoster !== null;
    },

    allDataAvailable() {
      return (
        this.allTeamsLoaded
        && this.sessionAvailable
        && this.sessionDataAvailable
        && this.homeTeamDataAvailable
        && this.homeTeamRosterAvailable
        // if there is away team, require team data & roster
        && ((this.session.AwayTeamID > 0 && this.awayTeamDataAvailable && this.awayTeamRosterAvailable) ||
          // pitching/batting practices may not have away team
          this.session.AwayTeamID == 0)
      );
    },
  },

  methods: {
    hover(points, id) {
      this.$log.debug(`${id}..c: ${points[0].x}, y: ${points[0].y}`);
    },

    fetchSession() {
      this.$store
        .dispatch(SESSION_REQUEST, this.selectedSession)
        .then((session) => {
          this.$log.debug('Session data promise has been resolved');
          if (this.homeTeamRoster === null && this.session.HomeTeamID > 0) {
            this.fetchHomeTeamRoster();
          }
          if (this.awayTeamRoster === null && this.session.AwayTeamID > 0) {
            this.fetchAwayTeamRoster();
          }
        })
        .catch((err) => {
          this.$log.warn('Session data promise has been rejected');
          this.$log.debug(err);
          captureException(err);
        });

        this.$log.debug('Session data requested.');
      },
      fetchHomeTeamRoster() {
        if (this.sessionAvailable) {
          this.$store.dispatch(ROSTER_REQUEST, this.session.HomeTeamID).catch((err) => {
            this.$log.warn('Home team roster data promise has been rejected');
            this.$log.debug(err);
            captureException(err);
          });
          this.$log.debug('Home team roster data requested.');
        }
      },
    fetchAwayTeamRoster() {
      if (this.sessionAvailable) {
        this.$store.dispatch(ROSTER_REQUEST, this.session.AwayTeamID).catch((err) => {
          this.$log.warn('Away team roster data promise has been rejected');
          this.$log.debug(err);
          captureException(err);
        });
        this.$log.debug('Away team roster data requested.');
      }
    },
    fetchData() {
      if (!this.sessionDataAvailable) {
        this.fetchSession();
      } else {
        if (!this.homeTeamRosterAvailable) {
          this.fetchHomeTeamRoster();
        }
        if (this.sessionHasAwayTeam && !this.awayTeamRosterAvailable) {
          this.fetchAwayTeamRoster();
        }
      }
    },
    sessionUpdate(newVal) {
      this.fetchData();
    },

    prepareSprayChart() {
      if (!this.allDataAvailable) {
        return;
      }

      let filtersMapped = {
        batterId: this.session.resultsv1.find((result) => result.RelatedBatterTagID).RelatedBatterTagID,
      };

      let { series: seriesSi, resultsMap } = Providers.SprayChartSeriesProvider(
        this.session.resultsv1,
        undefined,
        filtersMapped
      );

      let mappedSeries = seriesSi.map(Mappers.mapToPlotly);

      mappedSeries = mappedSeries.map((item) => {
        return {
          ...item,
          hovertemplate: Layouts.hoverTemplates.sprayChartHoverTemplate,
        };
      });

      this.sprayChartData = [Traces.baseballFoulLineTraceSI, Traces.baseballGrassLineTraceSI, ...mappedSeries];
    },

    updateSeries() {
      if (!this.allDataAvailable) {
        return;
      }

      this.loading = true;

      this.prepareSprayChart();

      this.loading = false;
    },

    watchSessionData(value) {
      if (value) {
        this.session = sessionFactory.createSession(value);
        this.updateSeries();
      }
    },

    watchAllSessionData(value) {
      if (value) {
        this.updateSeries();
      }
    },
  },

  mounted() {
    this.$log.debug(`${this.$options.name} mounted`);
  },

  watch: {
    allDataAvailable: {
      handler: 'watchAllSessionData',
    },
    selectedSession: {
      handler: 'sessionUpdate',
      immediate: true,
    },
    sessionData: {
      handler: 'watchSessionData',
      immediate: true,
    },
  },
};
