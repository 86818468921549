<template >
  <v-card dark outlined eager class="mx-auto mb-12" min-height="200">
    <v-row no-gutters>
      <v-col cols="12" md="9">
        <slot></slot>
      </v-col>
      <v-col cols="12" md="3">
        <slot name="options"></slot>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'Example',
};
</script>
