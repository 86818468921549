import TimeHandling from '@/modules/ui-examples/TimeHandling.vue';
import Pickers from '@/modules/ui-examples/Pickers.vue';
import DataTableControls from '@/modules/ui-examples/DataTableControls.vue';
import SelectionControls from '@/modules/ui-examples/SelectionControls.vue';
import PlotlyExamples from '@/modules/ui-examples/PlotlyExamples.vue';
import StrikeZoneHmapExample from  '@/modules/ui-examples/StrikeZoneHmapExample.vue';
import SeasonSelectExample from  '@/modules/ui-examples/SeasonSelectExample.vue';

export default [
  {
    path: 'selection-controls',
    name: 'ui.selectionControls',
    component: SelectionControls,
  },
  {
    path: 'time-heist',
    name: 'ui.timeHeist',
    component: TimeHandling,
  },
  {
    path: 'pickers',
    name: 'ui.pickers',
    component: Pickers,
  },
  {
    path: 'data-table-controls',
    name: 'ui.data-table-controls',
    component: DataTableControls,
  },
  {
    path: 'plotly-examples',
    name: 'ui.plotly-examples',
    component: PlotlyExamples,
  },
  {
    path: 'strike-zone-hmap',
    name: 'ui.strikeZoneHmap',
    component: StrikeZoneHmapExample,
  },
  {
    path: 'season-select',
    name: 'ui.seasonSelect',
    component: SeasonSelectExample,
    props: {
      season: 2019// TODO: Pass proper value
    }
  }
];
