<script>
import ZONES from '../../../providers/ZoneProvider';
import Selectable from '../../mixins/Selectable';
import ZoneGroup from '../selection-controls/ZoneGroup.vue';

const MSG = {
  title: 'Select specific Zone or group of Zones.',
  btn_all: 'All zones',
  btn_outer: 'Out of zone',
  btn_inner: 'In zone',
  btn_clear: 'Clear',
};

export default {
  name: 'ZonePicker',
  mixins: [Selectable],
  components: {
    ZoneGroup,
  },
  data() {
    return {
      messages: MSG,
      zones: ZONES,
    };
  },
};
</script>

<template>
<v-card max-width="408" max-height="258" class="pa-6 zone-picker">
  <v-container class="pa-0">
    <v-row justify="space-between" no-gutters class="flex-nowrap">
      <v-col cols="auto">
        <zone-group v-model="selected"></zone-group>
      </v-col>
      <v-col cols="auto flex-shrink-1">
        <p class="mx-2 header_bg--text caption">{{ messages.title }}</p>
        <v-card-actions class="flex-column">
          <v-btn text color="primary" @click="selected = zones.all">{{ messages.btn_all }}</v-btn>
          <v-btn text color="primary" @click="selected = zones.outer">{{ messages.btn_outer }}</v-btn>
          <v-btn text color="primary" @click="selected = zones.inner">{{ messages.btn_inner }}</v-btn>
          <v-btn text color="primary" @click="selected = []">{{ messages.btn_clear }}</v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</v-card>
</template>

<style lang="scss" scoped>
.zone-picker::v-deep {
  .zone-group {
    width: 200px;
    height: 200px;
  }
  .v-btn {
    height: 32px;
    font-size: 16px;
  }
}

</style>
