<script>
import { dropdownIcon } from '@/utils/helpers';
import ZONES from '../../../providers/ZoneProvider';
import Selectable from '../../mixins/Selectable';
import ZonePicker from '../pickers/ZonePicker.vue';

export default {
  name: 'ZoneDropdown',
  mixins: [Selectable],
  components: {
    ZonePicker,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    allText: {
      type: String,
      default: 'All',
    },
  },
  data() {
    return {
      menu: false,
      allValues: ZONES.all,
    };
  },
  computed: {
    textFieldValue() {
      if (this.selected.length && this.selected.length !== this.allValues.length) {
        return `Selected ${this.selected.length} zones`;
      }
      return this.allText;
    },
    appendIcon() {
      return dropdownIcon(this.menu);
    }
  },
};
</script>

<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-click="true"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="219"
    max-width="408"
    offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="textFieldValue"
        :label="label"
        dense
        hide-details
        outlined
        readonly
        v-on="on"
        :append-icon="appendIcon"
        @click:append="menu = !menu"
        v-bind="attrs"
      ></v-text-field>
    </template>
    <zone-picker v-model="selected"></zone-picker>
  </v-menu>
</template>
