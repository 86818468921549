<template>
<v-item-group
  multiple
  v-model="selected"
  class="zone-group">
  <v-item
    v-for="(item, i) in items"
    :key="item"
    v-slot:default="{ active, toggle }"
    :value="item"
  >
    <div
      class="zone"
      :class="[
        `zone-${item}`,
        `zone-index-${i}`,
        { 'side-zone ': item > 10 },
        { active: active }
      ]"
      @click="toggle"
    >
      <span class="zone-value">{{ item }}</span>
    </div>
  </v-item>
</v-item-group>
</template>>

<script>
import ZONES from '../../../providers/ZoneProvider';
import Selectable from '../../mixins/Selectable';

export default {
  name: 'ZoneGroup',
  mixins: [Selectable],
  data() {
    return {
      items: ZONES.all,
    };
  },
};
</script>

<style lang="scss" scoped>
$border-width: 1px;

.zone-group {
  --count: 10;
  --perc: calc(1 / var(--count) * 100%);
  display: grid;
  grid-template-columns: repeat(var(--count), var(--perc));
  grid-template-rows: repeat(var(--count), var(--perc));
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.zone-value {
  color: var(--v-primary-base);
  font-size: 16px;
  text-decoration: none solid rgb(214, 0, 28);
}

.zone {
  z-index: 2;
  border: $border-width / 2 solid #8c8c8c;
  cursor: pointer;
  background-color: white;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background-color: var(--v-primary-base);

    .zone-value {
      color: white;
      text-decoration-color:#fff;
    }
  }
}

.side-zone {
  z-index: 1;
}

// BORDERS

%top-border {
  border-top-width: $border-width;
}
%left-border {
  border-left-width: $border-width;
}
%right-border {
  border-right-width: $border-width;
}
%bottom-border {
  border-bottom-width: $border-width;
}

// GRID ROWS:
%topOuterRow {
  @extend %top-border;
  align-items: flex-start;
  grid-row-start: 1;
  grid-row-end: 6;
}

%bottomOuterRow {
  @extend %bottom-border;
  align-items: flex-end;
  grid-row-start: 6;
  grid-row-end: 11;
}

%topInnerRow {
  @extend %top-border;
  grid-row-start: 3;
  grid-row-end: 5;
}

%middleInnerRow {
  grid-row-start: 5;
  grid-row-end: 7;
}

%bottomInnerRow {
  @extend %bottom-border;
  grid-row-start: 7;
  grid-row-end: 9;
}

// COLUMNS
%leftOuterColumn {
  justify-content: flex-start;
  grid-column-start: 1;
  grid-column-end: 6;
}

%rightOuterColumn {
  justify-content: flex-end;
  grid-column-start: 6;
  grid-column-end: 11;
}

%leftInnerColumn {
  grid-column-start: 3;
  grid-column-end: 5;
}

%middleInnerColumn {
  grid-column-start: 5;
  grid-column-end: 7;
}

%rightInnerColumn {
  grid-column-start: 7;
  grid-column-end: 9;
}

.zone-1 {
  @extend %topInnerRow;
  @extend %leftInnerColumn;
}
.zone-2 {
  @extend %topInnerRow;
  @extend %middleInnerColumn;
}
.zone-3 {
  @extend %topInnerRow;
  @extend %rightInnerColumn;
}

.zone-4 {
  @extend %middleInnerRow;
  @extend %leftInnerColumn;
}
.zone-5 {
  @extend %middleInnerRow;
  @extend %middleInnerColumn;
}
.zone-6 {
  @extend %middleInnerRow;
  @extend %rightInnerColumn;
}

.zone-7 {
  @extend %bottomInnerRow;
  @extend %leftInnerColumn;
}
.zone-8 {
  @extend %bottomInnerRow;
  @extend %middleInnerColumn;
}
.zone-9 {
  @extend %bottomInnerRow;
  @extend %rightInnerColumn;
}

.zone-11 {
  @extend %topOuterRow;
  @extend %leftOuterColumn;
}
.zone-12 {
  @extend %topOuterRow;
  @extend %rightOuterColumn;
}
.zone-13 {
  @extend %bottomOuterRow;
  @extend %leftOuterColumn;
}
.zone-14 {
  @extend %bottomOuterRow;
  @extend %rightOuterColumn;
}
</style>
