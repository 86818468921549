import { getCoords } from '../ChartHelpers';
import genericTraces from './genericTraces';

function getGrassLine({ x0, y0 } = { x0: 0, y0: 18.39 }, radius = 28.9) {
  let grassLineTrace = {
    key: 'grassLine',
    name: 'Grass Line',
    type: 'scatter',
    mode: 'lines',
    hoverinfo: 'skip',
    skipAdjust: true,
    x: [],
    y: [],
  };

  // get arc points
  for (let i = 0.33; i < 2.83; i += 0.02) {
    let { x, y } = getCoords({ x0, y0 }, radius, i);

    grassLineTrace.x.push(x);
    grassLineTrace.y.push(y);
  }

  // get last point
  let { x, y } = getCoords({ x0, y0 }, radius, 2.83);

  grassLineTrace.x.push(x);
  grassLineTrace.y.push(y);

  return grassLineTrace;
}

const baseballGrassLineTraceSI = getGrassLine();

const baseballFoulLineTraceSI = {
  key: 'foulLine',
  name: 'Foul Line',
  type: 'scatter',
  mode: 'lines',
  hoverinfo: 'skip',
  skipAdjust: true,
  x: [-65, 0, 65, 19.4, 0, -19.4],
  y: [65, 0, 65, 19.4, 38.8, 19.4],
};


const trace1 = {
  x: [1, 2, 3],
  y: [1, 3, 1],
};

const trace2 = {
  x: [1, 2, 3],
  y: [1, 5, 5],
  type: 'scatter',
  mode: 'markers',
};

const trace2uno = {
  x: [10],
  y: [10],
  type: 'scatter',
  mode: 'markers',
};

const trace3 = {
  z: [
    [1, 20, 30, 5],
    [20, 1, 60, 5],
    [30, 60, 1, 5],
  ],
  x0: 0.5,
  y0: 0.5,
  type: 'heatmap',
};

export default {
  baseballFoulLineTraceSI,
  baseballGrassLineTraceSI,
  tests: {
    trace1,
    trace2,
    trace2uno,
    trace3,
  },
  generic: genericTraces,
};
