<script>
export default {
  name: 'SeasonSelectExample',
  props: {
    season: {
      type: Number,
    },
  },
  data() {
    return {
      items: [
        { text: 2019, value: 2019 },
        { text: 2020, value: 2020 },
        { text: 2021, value: 2021 },
      ],
    };
  },
  methods: {
    watchSeason(season) {
      console.log('[DW]', 'change season', season);
    },
    onChange(season) {
      this.$router.push({ params: { season } });
    },
  },
  // watch: {
  //   season: {
  //     handler: 'watchSeason',
  //   },
  // },
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card dark>
          <v-card-actions>
            <v-select label="Select season" :value="season" :items="items" @change="onChange"></v-select>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
