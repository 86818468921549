<script>
import ZoneDropdown from '@/components/ui/dropdowns/ZoneDropdown.vue';
import PlayOutcomeDropdown from '@/components/filters/PlayOutcomeDropdown.vue';

import TabbedGridDropdown from '@/components/ui/dropdowns/TabbedGridDropdown.vue';
import TabbedGridPicker from '@/components/ui/pickers/TabbedGridPicker.vue';

import Example from './Example.vue';
import { mapOptionsToSelect } from '@/utils/mappers';
import ResultFilter from '@/filters/ResultFilter';

export default {
  name: 'Pickers',
  components: {
    Example,
    ZoneDropdown,
    PlayOutcomeDropdown,
    TabbedGridPicker,
    TabbedGridDropdown,
  },
  data() {
    return {
      selected: [],
      selected2: [],
      checkboxItemGroupSelectedTab: null,

      selectedPlayOutcome: [],
    };
  },

  computed: {
    checkboxItemGroupTabs() {
      return mapOptionsToSelect(ResultFilter.PlayOutcome.key);
    },
  },
};
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <section class="page fill-height">
          <example>
            <h1>PlayOutcomeDropdown</h1>

            <code>{{ selectedPlayOutcome }}</code>

            <v-container>
              <v-col cols="12">
                <tabbed-grid-picker
                  v-model="selectedPlayOutcome"
                  :options="checkboxItemGroupTabs"
                  :tab.sync="checkboxItemGroupSelectedTab"
                  clearable
                  multiple
                ></tabbed-grid-picker>

              </v-col>
              <v-col cols="12">
                <tabbed-grid-dropdown
                  v-model="selectedPlayOutcome"
                  :options="checkboxItemGroupTabs"
                  label="Tabbed Grid Dropdown"
                  clearable
                  multiple
                  group-all
                ></tabbed-grid-dropdown>

              </v-col>
              <v-col cols="12">
                <play-outcome-dropdown
                  v-model="selectedPlayOutcome"
                ></play-outcome-dropdown>
              </v-col>
            </v-container>
          </example>

          <example class="pa-4">
            <zone-dropdown v-model="selected" label="Zone" all-text="All Zones"></zone-dropdown>
            <template v-slot:options>
              <pre><code>{{ selected }}</code></pre>
            </template>
          </example>
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
</style>
